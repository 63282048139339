<template>
  <div class="main-box">
    <MainBodyHeader title="پروفایل تخصصی">
      <CRow
        class="w-100"
        style="justify-content: space-between"
        align-vertical="center"
      >
        <h4 class="text-danger">
          در صورت تغییر در مدارک ارسالی و ذخیره تغییرات ؛ مدارک شما مجدد باید
          توسط مدیر تایید شود.
        </h4>
        <button class="btn btn-success p-2" @click="submit()">
          ذخیره تغییرات
        </button>
      </CRow>
    </MainBodyHeader>
    <vue-element-loading
      :active="loading"
      text="دریافت اطلاعات شما..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CRow class="w-100">
      <CCol col="3">
        <div class="main-card vertical-g-5">
          <div
            style="
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div class="file-box number-box mb-2">
              <h4>سابقه کار به ماه</h4>
              <input type="number" placeholder="0" v-model="WorkExperience" />
            </div>
            <select v-model="advicerType" class="advicerType-select">
              <option :value="0" selected disabled>نوع همکاری...</option>
              <option :value="1">هر دو حالت</option>
              <option :value="2">حضوری</option>
              <option :value="3">غیر حضوری</option>
            </select>
          </div>
          <div class="file-box btn btn-secondary">
            <label for="NationalCard" class="custom-file-upload">
              <i class="fa fa-upload" aria-hidden="true"></i>
              {{ imageText.NationalCard }}
            </label>
            <input
              id="NationalCard"
              name="NationalCard"
              type="file"
              @change="onFileChangeFile"
            />
            <a target="_blank" v-if="NationalCardUrl" :href="NationalCardUrl"
              ><i class="fa fa-download" aria-hidden="true"></i
            ></a>
          </div>
          <div class="file-box btn btn-secondary">
            <label for="BirthCertificate" class="custom-file-upload">
              <i class="fa fa-upload" aria-hidden="true"></i>
              {{ imageText.BirthCertificate }}
            </label>
            <input
              id="BirthCertificate"
              name="BirthCertificate"
              type="file"
              @change="onFileChangeFile"
            />
            <a
              target="_blank"
              v-if="BirthCertificateUrl"
              :href="BirthCertificateUrl"
              ><i class="fa fa-download" aria-hidden="true"></i
            ></a>
          </div>
          <div class="file-box btn btn-secondary">
            <label for="DegreeOfEducation" class="custom-file-upload">
              <i class="fa fa-upload" aria-hidden="true"></i>
              {{ imageText.DegreeOfEducation }}
            </label>
            <input
              id="DegreeOfEducation"
              name="DegreeOfEducation"
              type="file"
              @change="onFileChangeFile"
            />
            <a
              target="_blank"
              v-if="DegreeOfEducationUrl"
              :href="DegreeOfEducationUrl"
              ><i class="fa fa-download" aria-hidden="true"></i
            ></a>
          </div>
          <div class="file-box btn btn-secondary">
            <label for="Resume" class="custom-file-upload">
              <i class="fa fa-upload" aria-hidden="true"></i>
              {{ imageText.Resume }}
            </label>
            <input
              id="Resume"
              name="Resume"
              type="file"
              @change="onFileChangeFile"
            />
            <a target="_blank" v-if="ResumeUrl" :href="ResumeUrl"
              ><i class="fa fa-download" aria-hidden="true"></i
            ></a>
          </div>
        </div>
      </CCol>
      <CCol col="9"
        ><div class="main-card mt-2">
          <textarea
            class="w-100"
            rows="8"
            v-model="Text"
            style="direction: rtl"
            placeholder="توضیحاتی مربوط به خودتان برای نمایش به کارفرما را می توانید در این بخش بنویسید (این توضیحات برای همکاری شما با کارفرما تاثیر مثبت دارد)"
          ></textarea></div
      ></CCol>
    </CRow>
    <CRow class="w-100 mt-2">
      <CCol col="6">
        <div class="main-card">
          <h5 class="main-card-title">
            تخصص های خود را از لیست زیر انتخاب کنید (می توانید چند تخصص انتخاب
            کنید)
          </h5>
          <div class="expertise-box">
            <input
              type="text"
              class="main-card-search"
              placeholder="جستجوی تخصص (عبارت مورد نظر را وارد کنید و کلید اینتر را بزنید) ..."
            />
            <button class="main-card-list-button btn-success">
              لیست تخصص های انتخاب شده
            </button>
            <!-- <component :is="createTree"></component> -->
            <div v-html="expertiseTree" ref="checkTree"></div>
          </div>
        </div>
      </CCol>
      <CCol col="6">
        <div class="main-card">
          <h5 class="main-card-title">
            با انتخاب یک نقطه محل خود را روی نقشه به کارفرما نمایش دهید
          </h5>
          <div class="map-box">
            <div id="map" ref="map" />
            <geocode-search
              @selectedGeocode="geocodeFind"
              style="right: 1vw; top: 1vh; width: 15vw"
            ></geocode-search>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MainBodyHeader from "../../components/mainHeader.vue";
import { apiUrlRoot } from "../../constants/config";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import GeocodeSearch from "../../components/map/GeocodeSearch.vue";

export default {
  name: "userExpertiseForm",
  components: {
    MainBodyHeader,
    GeocodeSearch,
  },
  data() {
    return {
      loading: false,
      imageText: {
        DegreeOfEducation: "تصویر آخرین مدرک تحصیلی",
        BirthCertificate: "تصویر صفحه اول شناسنامه",
        NationalCard: "تصویر کارت ملی",
        Resume: "فایل رزومه",
      },
      map: null,
      DegreeOfEducationFile: null,
      BirthCertificateFile: null,
      NationalCardFile: null,
      ResumeFile: null,
      DegreeOfEducationUrl: "",
      BirthCertificateUrl: "",
      NationalCardUrl: "",
      ResumeUrl: "",
      WorkExperience: null,
      Text: "",
      advicerId: 0,
      farmuserId: 0,
      userExpertiseSelected: [],
      expertiseList: [],
      expertiseTree: "",
      lng: "",
      lat: "",
      advicerType: null,
    };
  },

  methods: {
    ...mapActions("dashboard", [
      "GetAllExpertise",
      "GetAdvicerExpertiseProfile",
      "SaveAdvicerExpertiseProfile",
    ]),
    async fetchData() {
      this.loading = true;
      let resultAdvicer = await this.GetAdvicerExpertiseProfile({});
      this.loading = false;
      this.advicerId = resultAdvicer.advicerId;
      this.WorkExperience = resultAdvicer.workExperience;
      this.Text = resultAdvicer.text;
      this.advicerType = resultAdvicer.advicerType;
      this.userExpertiseSelected = resultAdvicer.expertise;
      if (resultAdvicer.location) {
        let lng = JSON.parse(resultAdvicer.location)[0];
        let lat = JSON.parse(resultAdvicer.location)[1];
        this.map._markers.forEach((element) => {
          element.remove();
        });
        new mapboxgl.Marker().setLngLat({ lng, lat }).addTo(this.map);
        this.map.flyTo({ center: [lng, lat], zoom: 10 });
      }
      this.DegreeOfEducationUrl = resultAdvicer.degreeOfEducation
        ? `${apiUrlRoot}/Expertise/${resultAdvicer.degreeOfEducation}`
        : null;
      this.BirthCertificateUrl = resultAdvicer.birthCertificate
        ? `${apiUrlRoot}/Expertise/${resultAdvicer.birthCertificate}`
        : null;
      this.NationalCardUrl = resultAdvicer.nationalCard
        ? `${apiUrlRoot}/Expertise/${resultAdvicer.nationalCard}`
        : null;
      this.ResumeUrl = resultAdvicer.resume
        ? `${apiUrlRoot}/Expertise/${resultAdvicer.resume}`
        : null;
    },

    async createTree() {
      this.expertiseList = [];
      this.loading = true;
      let result = await this.GetAllExpertise({});
      this.loading = false;
      if (result) {
        this.expertiseList = result.map((item) => {
          return {
            id: item.id,
            parentId: item.parentId,
            name: item.name,
            label: item.label,
            description: item.description,
          };
        });
        if (this.expertiseList.length > 0) {
          let elTree = `<ul class="tree">`;
          let parentNode = this.expertiseList.filter((x) => x.parentId == null);
          parentNode.forEach((element) => {
            let li = `<li><details open><summary><input type="checkbox"
              ${
                this.userExpertiseSelected.includes(element.id) == true
                  ? "checked"
                  : ""
              } onclick="userExpertise(this)" id="${element.id}" che  value="${
              element.id
            }"/> ${element.label}</summary>`;
            li += this.createTreeChild(element.id, this.expertiseList);
            li += `</details></li>`;
            elTree += li;
          });
          elTree += `</ul>`;
          this.expertiseTree = elTree;
        } else {
          return "";
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "امکان تکمیل حساب کاربری نیست با پشتیبانی تماس بگیرید<br /><br />",
          type: "error",
        });
      }
    },
    createTreeChild(parentId, model) {
      let elTree = `<ul>`;
      model.forEach((element) => {
        if (element.parentId == parentId) {
          let li = `<li><details open><summary><input type="checkbox"  onclick="userExpertise(this)" 
            ${
              this.userExpertiseSelected.includes(element.id) == true
                ? "checked"
                : ""
            }  id="${element.id}" value="${element.id}"/> ${
            element.label
          }</summary>`;
          li += this.createTreeChild(element.id, model);
          li += `</details></li>`;
          elTree += li;
        }
      });
      elTree += `</ul>`;
      return elTree;
    },

    userExpertise(el) {
      if (el.checked) {
        this.userExpertiseSelected.push(+el.id);
      } else {
        let index = this.userExpertiseSelected.indexOf(+el.id, 0);
        if (index > -1) {
          this.userExpertiseSelected.splice(index, 1);
        }
      }
    },
    onFileChangeFile(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      console.log(e.target.name);
      switch (e.target.name) {
        case "DegreeOfEducation":
          this.DegreeOfEducationFile = files[0];
          this.imageText.DegreeOfEducation = this.DegreeOfEducationFile.name;
          break;
        case "BirthCertificate":
          this.BirthCertificateFile = files[0];
          this.imageText.BirthCertificate = this.BirthCertificateFile.name;
          break;
        case "NationalCard":
          this.NationalCardFile = files[0];
          this.imageText.NationalCard = this.NationalCardFile.name;
          break;
        case "Resume":
          this.ResumeFile = files[0];
          this.imageText.Resume = this.ResumeFile.name;
          break;
        default:
          return;
      }
    },

    async submit() {
      let formData = new FormData();
      formData.append("DegreeOfEducation", this.DegreeOfEducationFile);
      formData.append("BirthCertificate", this.BirthCertificateFile);
      formData.append("NationalCard", this.NationalCardFile);
      formData.append("Resume", this.ResumeFile);
      formData.append("WorkExperience", this.WorkExperience);
      formData.append("Text", this.Text);
      formData.append("ExpertisesId", this.userExpertiseSelected);
      formData.append("AdvicerId", this.advicerId);
      formData.append("Lng", this.lng);
      formData.append("Lat", this.lat);
      formData.append("AdvicerType", this.advicerType);
      this.loading = true;
      let result = await this.SaveAdvicerExpertiseProfile({
        expertiseinfo: formData,
      });
      this.loading = false;
      if (result.succeeded) {
        this.$notify({
          title: "پیام سیستم",
          text: "اطلاعات تخصصی شما به روز شد<br /><br />",
          type: "success",
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در ثبت اطلاعات ...<br /><br />",
          type: "error",
        });
      }
    },

    geocodeFind(coords) {
      this.map.fitBounds([
        [coords[2], coords[0]], // southwestern corner of the bounds
        [coords[3], coords[1]], // northeastern corner of the bounds
      ]);
    },
    SetLocation(lng, lat) {
      this.map._markers.forEach((element) => {
        element.remove();
      });
      new mapboxgl.Marker().setLngLat({ lng, lat }).addTo(this.map);
      this.lng = lng;
      this.lat = lat;
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmlvY2hpYXBwIiwiYSI6ImNsMzhiOHdyaTA1OXYzZG13aGI5bXc0Y2YifQ.KK2ncJJG5hJWECxJ9jQwCA";

    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true
      );
    }

    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/biochiapp/cl4chu50q000314qsi4ng5wj1",
      center: [53.12609431323281, 33.59332186046887],
      zoom: 4,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    this.fetchData().then(() => {
      this.createTree();
    });

    this.map.on("click", (e) => {
      this.SetLocation(e.lngLat.lng, e.lngLat.lat);
    });
  },
  created() {
    window.userExpertise = this.userExpertise;
  },
};
</script>

<style scoped>
.h-45 {
  height: 45% !important;
}
.main-card-search[type="text"] {
  width: 65%;
  border: none;
  border-bottom: 1px solid #4caf50;
  padding-right: 5px;
  outline: none;
  margin-top: 4px;
  margin-right: 7px;
  border-radius: 6px;
  margin-left: 10px;
  padding: 5px 5px;
}
.main-card-list-button {
  border: none;
  padding: 5px 12px;
  position: absolute;
  left: 2vw;
  top: 5vh;
  border-radius: 10px;
}
.main-card-title {
  font-size: 1em;
  margin: 6px 0px;
  text-align: right;
  color: #1b5e20;
  font-weight: bold;
}
textarea {
  resize: none;
  outline: none;
  padding: 4px;
}
.btn-action {
  border: none;
  background-color: #2e7d32;
  padding: 0.3em 1em;
  color: #fff;
  border-radius: 14px;
}

.file-box {
  display: flex;
  height: 4vh;
  align-items: center;
  justify-content: space-between;
}
input[type="file"] {
  display: none;
}
.file-box a {
  background-color: #ffffff;
  color: green;
  padding: 0px 10px;
  border-radius: 6px;
  cursor: pointer;
}
.custom-file-upload {
  display: inline-block;
  padding: 0px 0px;
  cursor: pointer;
  margin: 0px;
}

.number-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: -1px 2px 6px -3px #000;
  border-radius: 7px;
  border-right: 4px solid #e91e63;
}
.number-box h4 {
  flex-grow: 3;
  font-size: 13px;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}
.number-box input[type="number"] {
  flex-grow: 2;
  width: 5vw;
  margin: 0px 8px;
  border: none;
  border-bottom: 1px solid #607d8b;
  text-align: center;
  outline: none;
}
.number-box i {
  flex-grow: 1;
  font-size: 2em;
  color: #607d8b;
}

.map-box {
  width: 100%;
  height: 50vh;
  position: relative;
}

#map {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.expertise-box {
  width: 100%;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgb(235 235 235);
}
.advicerType-select {
  border-radius: 10px;
  flex-grow: 1;
  margin: 0px 5px 5px 5px;
  padding: 7px 0px;
  outline: none;
  border: 1px solid #bbb;
  border-right: 4px solid #e91e63;
}
</style> 
<style >
.tree {
  --spacing: 1.5rem;
  --radius: 20px;
  margin-top: 1vh;
  padding-right: 1vh;
  height: 85%;
}

.tree li {
  display: block;
  position: relative;
  padding-right: calc(2 * var(--spacing) - var(--radius) - 2px);
}
.tree input[type="checkbox"] {
  z-index: 11;
}
.tree ul {
  margin-right: calc(var(--radius) - var(--spacing));
  padding-right: 0;
}

.tree ul li {
  border-right: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
}

.tree ul li:last-child {
  border-color: transparent;
}

.tree ul li::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(var(--spacing) / -2);
  right: -2px;
  width: calc(var(--spacing) + 2px);
  height: calc(var(--spacing) + 1px);
  border: solid #ddd;
  border-width: 0 2px 2px 0px;
}

.tree summary {
  cursor: pointer;
  padding: 3px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 3px;
}

.tree summary::marker,
.tree summary::-webkit-details-marker {
  display: none;
}

.tree summary:focus {
  outline: none;
}

.tree summary:focus-visible {
  outline: 1px dotted #000;
}

/* .tree li::after,
.tree summary::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(var(--spacing) / 2 - var(--radius));
  right: calc(var(--spacing) - var(--radius) - 1px);
  width: calc(2 * var(--radius));
  height: calc(2 * var(--radius));
  border-radius: 50%;
  background: #ddd;
} */
.tree summary::before {
  content: "\A";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #3cfd0b;
  display: inline-block;
}
/* .tree summary::before {
  z-index: 1;
  background: #696 url("../../assets/icons/expand-collapse.svg") 0 0;
}

.tree details[open] > summary::before {
  background-position: calc(-2 * var(--radius)) 0;
} */
</style>